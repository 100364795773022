import GoogleMapReact from "google-map-react";

type MapaProps = {
  lat: number;
  lng: number;
};

export default function Mapa({ lat, lng }: MapaProps) {
  return (
    <div className="row mb-5">
      <div className="col-lg-12">
        <p className="title-h1">Localização</p>

        <div style={{ height: "35vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "" }}
            defaultCenter={{ lat: lat, lng: lng }}
            defaultZoom={20}
          ></GoogleMapReact>
        </div>
      </div>
    </div>
  );
}
