import { useNavigate } from "react-router-dom";
import { CleanLayout, LinkVoltarParaLogin } from "../../Components";
import FormRedefinirSenha from "./Components/FormRedefinirSenha";
import { useEffect } from "react";
import LinkVoltarParaPerfil from "./Components/LinkVoltarParaPerfil";
import { useIsAuth } from "../../Hooks/useIsAuth";

export default function RedefinirSenha() {
  const navigate = useNavigate();

  const isAuth: boolean = useIsAuth();

  useEffect(() => {
    if (isAuth && !window.location.pathname.includes("meu-perfil")) {
      navigate("/meu-perfil/redefinir-senha");
      return;
    }
  }, []);

  return (
    <>
      <CleanLayout>
        {window.location.pathname.includes("meu-perfil") ? (
          <LinkVoltarParaPerfil />
        ) : (
          <LinkVoltarParaLogin />
        )}

        <p className="title-h1 text-black pt-5">Crie sua nova senha</p>

        <p className="text-400-black-18 mb-5">
          Informe abaixo sua nova senha de acesso
        </p>

        <FormRedefinirSenha />
      </CleanLayout>
    </>
  );
}
