import { BreadCrumb, CardEvento, Layout, Loading } from "../../Components";
import { Page } from "../../Components/Breadcrumb";

import AxiosClient from "../../Services/AxiosClient";
import FiltroBusca from "./Components/FiltroBusca";
import toast from "react-hot-toast";

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IngressoContaModel } from "../../Interfaces/Models";
import ServiceResult from "../../Interfaces/ServiceResult";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "Página inicial",
  },
  {
    link: "/meus-ingressos",
    name: "Meus ingressos",
  },
];

export default function MeusIngressos() {
  const navigate = useNavigate();

  const [ingressosMap, setIngressosMap] = useState<
    Map<string, IngressoContaModel[]>
  >(new Map<string, IngressoContaModel[]>());

  const [ingressos, setIngressos] = useState<IngressoContaModel[]>([]);

  const [ingressosFiltrados, setIngressosFiltrados] = useState<
    IngressoContaModel[]
  >([] as IngressoContaModel[]);

  const [filtroAplicado, setFiltroAplicado] = useState<string>("proximos");

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const ingressosRecebidos: IngressoContaModel[] = Object.values(ingressosMap)
    .flat()
    .filter(
      (i: IngressoContaModel) =>
        i.estado === "Recebido" &&
        i.sessoes.some((s) => new Date(s) > new Date()),
    );

  const possuiIngressosRecebidos = ingressosRecebidos.length > 0;

  const ObterIngressos = async () => {
    AxiosClient.get<ServiceResult<Map<string, IngressoContaModel[]>>>(
      "/conta/ingressos",
    )
      .then(({ data: { data } }) => {
        const values = Object.values(data!);

        // Para não repetir os ingressos, pega apenas o primeiro de cada evento
        setIngressos(values.map((i) => i[0]));
        setIngressosMap(data!);

        // for (const [k, v] of Object.entries(data!)) {
        //   console.log("Evento:", k, "\nIngressos:", v);
        // }
      })
      .catch(() => toast.error("Não foi possível obter os seus ingressos"))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    ObterIngressos();
  }, []);

  useEffect(() => {
    FiltrarIngressos("proximos");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingressos]);

  const receberIngresso = async (
    ingressoId: string,
    aceitar: boolean,
  ): Promise<void> => {
    toast.promise(
      AxiosClient.put(`/conta/transferencias/${ingressoId}`, {
        aceitar,
      }),
      {
        loading: "Aguarde...",
        success: () => {
          ObterIngressos();

          return `Ingresso ${aceitar ? "recebido" : "recusado"} com sucesso!`;
        },
        error: (error: any) => {
          setTimeout(() => {
            navigate(0);
          }, 3000);

          toast("Recarregando página em 3 segundos...", {
            icon: "⏳",
            duration: 3000,
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });

          if (error.response.status >= 400 && error.response.status < 500) {
            const result: ServiceResult = error.response.data;
            return result.messages.map((m) => "➡️ " + m.message).join("\n\n");
          } else {
            return "Ocorreu um erro inesperado. Tente novamente mais tarde.";
          }
        },
      },
    );
  };

  const FiltrarIngressos = (query: string): void => {
    setFiltroAplicado(query);

    switch (query) {
      case "proximos":
        setIngressosFiltrados(ingressos.filter((i) => i.estado === "Proximo"));
        break;

      case "concluidos":
        setIngressosFiltrados(
          ingressos.filter((i) => i.estado === "Concluido"),
        );
        break;

      default:
        setIngressosFiltrados(ingressos);
    }
  };

  return (
    <>
      <Layout>
        <div className="row g-3">
          <BreadCrumb history={breadCrumbHistory} />

          <div className="col-lg-12">
            <div
              className="bg-white py-3 pb-5 px-3 px-lg-5 shadow-sm"
              style={{ minHeight: "calc(100vh - 339px)" }}
            >
              <p className="title-h1 text-black m-0">Meus ingressos</p>

              {!isLoading && ingressos && (
                <FiltroBusca
                  aplicarFiltro={(query) => FiltrarIngressos(query)}
                />
              )}

              {isLoading && <Loading container="50vh" />}

              {!isLoading && ingressosFiltrados && (
                <div className="row g-3">
                  {ingressosFiltrados.map((ingresso) => (
                    <Link
                      to={`/meus-ingressos/${ingresso.eventoId}`}
                      key={ingresso.id}
                      className="col-xs-6 col-sm-6 col-md-4 col-lg-3 mt-4 mt-lg-5 d-flex justify-content-center text-decoration-none"
                    >
                      <CardEvento
                        imagemThumbEvento={ingresso.imagemEvento}
                        dataInicio={ingresso.sessoes[0]}
                        titulo={ingresso.titulo}
                        concluido={filtroAplicado === "concluidos"}
                      />
                    </Link>
                  ))}
                </div>
              )}

              {ingressosFiltrados?.length === 0 && !isLoading && (
                <p className="mt-2 text-center">Nenhum ingresso encontrado.</p>
              )}

              {!isLoading && possuiIngressosRecebidos && (
                <>
                  <p className="title-h2 text-black m-0 mt-5">Recebidos</p>

                  <div className="row g-3">
                    {ingressosRecebidos.map((ingresso) => (
                      <div
                        key={ingresso.id}
                        className="col-xs-6 col-sm-6 col-md-4 col-lg-3 mt-4 mt-lg-5 d-flex justify-content-center text-decoration-none"
                      >
                        <div className="card-evento">
                          <div className="shadow-sm mb-2 position-relative">
                            <Link to={`/evento/${ingresso.eventoId}`}>
                              <img
                                src={ingresso.imagemEvento}
                                alt={ingresso.titulo}
                                className="img-card-evento mb-2"
                              />
                            </Link>
                            <div className="bg-white d-flex align-items-center justify-content-around position-absolute bottom-0 w-100">
                              <button
                                className="w-100 btn rounded-0 text-success fw-semibold"
                                onClick={() =>
                                  receberIngresso(ingresso.id, true)
                                }
                              >
                                Aceitar
                              </button>
                              <div className="vr bg-dark"></div>
                              <button
                                className="w-100 btn rounded-0 text-danger fw-semibold"
                                onClick={() =>
                                  receberIngresso(ingresso.id, false)
                                }
                              >
                                Recusar
                              </button>
                            </div>
                          </div>
                          <p className="text-500-black-16 text-break mb-1">
                            {ingresso.titulo.length > 35
                              ? `${ingresso.titulo.substring(0, 35)}...`
                              : ingresso.titulo}
                          </p>
                          {ingresso.sessoes[0] ? (
                            <p className="text-500-darkest-16 m-0">
                              {new Date(ingresso.sessoes[0]).toLocaleDateString(
                                "pt-br",
                                {
                                  day: "2-digit",
                                  month: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                },
                              )}
                            </p>
                          ) : (
                            <p className="text-500-darkest-14 m-0">
                              Nenhuma sessão disponível.
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
