import { Button, Loading } from "../../../Components";
import { LocalIcon, HeartIcon } from "../../../Components/Icons";

import AxiosClient from "../../../Services/AxiosClient";
import ServiceResult from "../../../Interfaces/ServiceResult";
import toast from "react-hot-toast";

import { EventoFavoritoModel } from "../../../Interfaces/Models";
import { useIsAuth } from "../../../Hooks/useIsAuth";
import { useEffect, useState } from "react";

type BannerProps = {
  eventoId: string | undefined;
  banner: string;
  nome: string;
  data: string;
  local: string;
  selecaoIngresso: boolean;
  atualizaEtapa: () => void;
};

export default function Banner({
  eventoId,
  banner,
  nome,
  data,
  local,
  selecaoIngresso,
  atualizaEtapa,
}: BannerProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eventoFavoritado, setEventoFavoritado] = useState<boolean>(false);
  const isAuth = useIsAuth();

  const AlternarEventoFavorito = async () => {
    setIsLoading(true);

    AxiosClient.put(`/conta/eventos-favoritos/${eventoId}`)
      .then((response) => {
        const result: ServiceResult<EventoFavoritoModel> = response.data;
        setEventoFavoritado(result.data?.estaFavoritado ?? false);
      })
      .catch((error) => toast.error("Não foi possível favoritar o evento."))
      .finally(() => setIsLoading(false));
  };

  const ObterEventoFavorito = async () => {
    setIsLoading(true);

    AxiosClient.get(`/conta/eventos-favoritos/${eventoId}`)
      .then((response) => {
        const result: ServiceResult<EventoFavoritoModel> = response.data;
        setEventoFavoritado(result.data?.estaFavoritado ?? false);
      })
      .catch((error) =>
        toast.error("Não foi possível obter o estado do evento.")
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!isAuth) return;

    ObterEventoFavorito();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row mb-4">
      <div className="col-lg-12">
        <div className="row">
          <img
            className="p-0"
            src={banner}
            alt="banner evento"
            width="100%"
            height="100%"
            style={{ maxHeight: "350px", borderRadius: "8px 8px 0 0" }}
          />
        </div>
        <div className="row bg-black" style={{ borderRadius: "0 0 8px 8px" }}>
          <div className={`col-lg-${isAuth ? "6" : "9"}`}>
            {/* nome do evento */}
            <p className="my-2 title-h2 text-white text-break">{nome}</p>
            <div className="col-lg-12 d-flex flex-wrap align-items-center">
              {/* icon + data */}
              {/*
              <div className="pb-3 d-flex align-items-center">
                <div className="pe-2">
                  <CalendarIcon />
                </div>

                <p className="m-0 me-4 text-500-white-16">
                  {new Date(data).toLocaleDateString("pt-br", {
                    day: "2-digit",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </p>
              </div>
              */}

              {/* icon + local */}
              <div className="pb-3 d-flex align-items-center">
                <div className="pe-2">
                  <LocalIcon />
                </div>
                <p className="m-0 me-4 text-500-white-16">{local}</p>
              </div>
            </div>
          </div>

          {/* Botão: adicionar favorito */}
          {isAuth && (
            <div className="col-lg-3 my-3 my-lg-0 d-flex align-items-center justify-content-center">
              <>
                {isLoading && <Loading />}

                {!isLoading && !eventoFavoritado && (
                  <Button
                    click={AlternarEventoFavorito}
                    className="bc-btn bc-btn-light p-2"
                    width="384px"
                    text="Adicionar aos favoritos"
                    iconSvg={<HeartIcon colored={false} />}
                  />
                )}

                {!isLoading && eventoFavoritado && (
                  <Button
                    click={AlternarEventoFavorito}
                    className="bc-btn bc-btn-light p-2"
                    width="384px"
                    text="Remover dos favoritos"
                    iconSvg={<HeartIcon colored={true} />}
                  />
                )}
              </>
            </div>
          )}

          {/* Botão: Ver ingressos */}
          <div className="col-lg-3 mb-3 mb-lg-0 d-flex align-items-center justify-content-center">
            {selecaoIngresso ? (
              <Button
                className="bc-btn bc-btn-primary"
                width="384px"
                text="Ver Informações"
                click={atualizaEtapa}
              />
            ) : (
              <Button
                className="bc-btn bc-btn-primary p-2"
                width="384px"
                text="Ver ingressos"
                click={atualizaEtapa}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
