import RedefinirSenhaArgs from "../Interfaces/Args/RedefinirSenhaArgs";
import AxiosClient from "../Services/AxiosClient";

import toast from "react-hot-toast";

import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function useRedefinirSenha() {
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { token } = useParams<{ token: string }>();

  const RedefinirSenha = async (data: RedefinirSenhaArgs) => {
    try {
      setLoading(true);

      if (pathname === "/meu-perfil/redefinir-senha") {
        await AxiosClient.post("/conta/redefinir-senha", {
          ...data,
        });

        toast.success("Senha alterada com sucesso!");

        navigate("/meu-perfil");

        return;
      }

      await AxiosClient.post("/conta/esqueci-minha-senha/nova-senha", {
        token,
        ...data,
      });

      toast.success("Senha redefinida com sucesso!");

      navigate("/login");
    } catch (err) {
      toast.error(
        `Erro ao redefinir a senha, verifique os dados informados e tente novamente`
      );
    } finally {
      setLoading(false);
    }
  };
  
  return { loading, pathname, RedefinirSenha }
}