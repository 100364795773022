import { Button, Loading, Modal } from "../../../Components";
import { MinusIcon, PlusIcon } from "../../../Components/Icons";

import IngressoSelecionado from "../../../Interfaces/IngressoSelecionado";

import { FormatDate, RemoverCaractereEspacosAcentos } from "../../../Utils";
import { FormatarCentavosParaReais } from "../../../Utils";
import { IngressoEventoModel } from "../../../Interfaces/Models";
import { useState } from "react";

type SelecaoIngressoProps = {
  nomeEvento: string;
  ingressos: IngressoEventoModel[];
  isLoading: boolean;
  comprar: (ingressosSelecionados: IngressoSelecionado[]) => void;
  validarCodigo: (codigoAcesso: string) => void;
};

export default function SelecaoIngresso({
  nomeEvento,
  ingressos,
  isLoading,
  comprar,
  validarCodigo,
}: SelecaoIngressoProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [inputCodigoAcesso, setInputCodigoAcesso] = useState("");
  const [ingressosSelecionados, setIngressosSelecionados] = useState<
    IngressoSelecionado[]
  >([] as IngressoSelecionado[]);

  const ingressosComCodigo: Record<string, IngressoEventoModel[]> = ingressos
    .filter((i) => i.codigoUtilizado)
    .reduce((acc: Record<string, IngressoEventoModel[]>, ingresso) => {
      const codigoUtilizado = ingresso.codigoUtilizado!;

      if (!acc[codigoUtilizado]) {
        acc[codigoUtilizado] = [];
      }

      acc[codigoUtilizado].push(ingresso);

      return acc;
    }, {});

  const ingressosSemCodigo: Record<string, IngressoEventoModel[]> = ingressos
    .filter((i) => !i.codigoUtilizado)
    .reduce((acc: Record<string, IngressoEventoModel[]>, ingresso) => {
      const grupoNome = ingresso.grupoNome!;
      if (!acc[grupoNome]) {
        acc[grupoNome] = [];
      }

      acc[grupoNome].push(ingresso);

      return acc;
    }, {});

  const addIngresso = (
    ingressoId: string,
    nome: string,
    valorCentavos: number,
    valorCentavosSemTaxa: number,
    codigoUtilizado: string | null,
    valorTaxaProcessamento: number,
    valorTaxaServico: number
  ) => {
    const ingressoSelecionado = ingressosSelecionados.find(
      (i) => i.ingressoId === ingressoId
    );

    if (ingressoSelecionado) {
      ingressoSelecionado.quantidade++;
    } else {
      ingressosSelecionados.push({
        ingressoId,
        nome,
        quantidade: 1,
        valorCentavos,
        valorCentavosSemTaxa,
        codigoAcessoUtilizado: codigoUtilizado,
        valorTaxaProcessamento,
        valorTaxaServico,
        valorTaxa: valorTaxaServico + valorTaxaProcessamento,
      } as IngressoSelecionado);
    }

    setIngressosSelecionados([...ingressosSelecionados]);
  };

  const removeIngresso = (ingressoId: string) => {
    const ingressoSelecionado = ingressosSelecionados.find(
      (i) => i.ingressoId === ingressoId
    );

    if (ingressoSelecionado) {
      ingressoSelecionado.quantidade--;

      if (ingressoSelecionado.quantidade === 0) {
        const index = ingressosSelecionados.findIndex(
          (i) => i.ingressoId === ingressoId
        );

        ingressosSelecionados.splice(index, 1);
      }
    }

    setIngressosSelecionados([...ingressosSelecionados]);
  };

  return (
    <>
      {isLoading ? (
        <Loading container="100vh" />
      ) : (
        <>
          <div className="row">
            <div className="col-lg-12">
              <p className="title-h1 text-center">Escolha seu ingresso</p>
            </div>
          </div>

          {ingressos.length > 0 ? (
            <>
              {/* Accordion: Ingressos sem codigo */}
              {Object.keys(ingressosSemCodigo).map((grupo) => (
                <div key={grupo} className="row mb-5 justify-content-center">
                  {/* Grupo */}
                  <div className="col-lg-9">
                    <div className="accordion" id="ticketsAccordion">
                      <div
                        className="accordion-item"
                        style={{
                          borderRadius: "7px",
                          border: "none",
                          boxShadow: "none",
                          background: "transparent",
                        }}
                      >
                        <h2 className="accordion-header grupo-ingressos">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={
                              "#collapse" +
                              RemoverCaractereEspacosAcentos(grupo)
                            }
                            aria-expanded="true"
                            aria-controls={
                              "collapse" + RemoverCaractereEspacosAcentos(grupo)
                            }
                          >
                            <div className="d-flex flex-column">
                              <p className="text-500-black-16 mb-3">{grupo}</p>
                              <p
                                className="text-500-darkest-14 text-break m-0"
                                style={{ whiteSpace: "break-spaces" }}
                              >
                                {/* mapear as sessões de cada ingresso do grupo */}
                                {ingressosSemCodigo[grupo][0].sessoes
                                  .map((d: string) => FormatDate(d))
                                  .join("\t | \t")}
                              </p>
                            </div>
                          </button>
                        </h2>
                        <div
                          id={
                            "collapse" + RemoverCaractereEspacosAcentos(grupo)
                          }
                          className="row justify-content-center accordion-collapse collapse show"
                          // data-bs-parent="#ticketsAccordion" -> Omit the data-bs-parent attribute on each .accordion-collapse to make accordion items stay open when another item is opened.
                        >
                          {/* Ingressos */}
                          {ingressosSemCodigo[grupo].map(
                            (ingresso: IngressoEventoModel) => (
                              <div
                                key={ingresso.id}
                                className="col-11 col-lg-10 mt-3"
                              >
                                <div className="card-ingresso d-flex flex-column flex-sm-row">
                                  {/* Data */}
                                  <div className="data-card-ingresso col col-sm-2 pt-3 p-sm-4">
                                    <p className="m-0 text-dia-ingresso">
                                      {new Date(
                                        ingresso.sessoes[0]
                                      ).toLocaleDateString("pt-BR", {
                                        day: "2-digit",
                                      })}
                                    </p>
                                    <p className="m-0 text-mes-ingresso">
                                      {new Date(
                                        ingresso.sessoes[0]
                                      ).toLocaleDateString("pt-BR", {
                                        month: "short",
                                      })}
                                    </p>
                                  </div>
                                  {/* Nome ingresso + Nome evento */}
                                  <div className="col d-flex flex-column flex-sm-row justify-content-between align-items-center p-3 p-sm-4">
                                    <div className="d-flex flex-column justify-content-center mb-4 mb-sm-0">
                                      <p className="text-500-black-16 text-break m-0">
                                        {ingresso.nome}
                                      </p>
                                      <p className="text-500-black-16 text-break m-0">
                                        {nomeEvento}
                                      </p>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row align-items-center">
                                      {/* Valor (dinâmico) + Qtde */}
                                      <p className="text-400-darkest-16 m-0 mb-3 mb-sm-0 mx-sm-3">
                                        {FormatarCentavosParaReais(
                                          ingresso.valorCentavosSemTaxa
                                        )}
                                      </p>
                                      <div className="d-flex align-items-center">
                                        {(ingressosSelecionados.find(
                                          (i) => i.ingressoId === ingresso.id
                                        )?.quantidade ?? 0) < 1 ? (
                                          <div className="cursor-pointer">
                                            <MinusIcon disabled />
                                          </div>
                                        ) : (
                                          <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                              removeIngresso(ingresso.id)
                                            }
                                          >
                                            <MinusIcon />
                                          </div>
                                        )}
                                        <p
                                          className="text-500-black-16 m-0 text-center"
                                          style={{
                                            width: "50px",
                                            height: "25px",
                                          }}
                                        >
                                          {ingressosSelecionados.find(
                                            (i) => i.ingressoId === ingresso.id
                                          )?.quantidade || 0}
                                        </p>
                                        <div
                                          className="cursor-pointer"
                                          onClick={() =>
                                            addIngresso(
                                              ingresso.id,
                                              ingresso.nome,
                                              ingresso.valorCentavos,
                                              ingresso.valorCentavosSemTaxa,
                                              ingresso.codigoUtilizado,
                                              ingresso.valorTaxaProcessamento,
                                              ingresso.valorTaxaServico
                                            )
                                          }
                                        >
                                          <PlusIcon />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* Accordion: Ingressos agrupados por codigo utilizado */}
              {Object.keys(ingressosComCodigo).map((codigo) => (
                <div key={codigo} className="row mb-5 justify-content-center">
                  {/* Grupo */}
                  <div className="col-lg-9">
                    <div className="accordion" id="ticketsAccordion">
                      <div
                        className="accordion-item"
                        style={{
                          borderRadius: "7px",
                          border: "none",
                          boxShadow: "none",
                          background: "transparent",
                        }}
                      >
                        <h2 className="accordion-header grupo-ingressos">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={
                              "#collapse" +
                              RemoverCaractereEspacosAcentos(codigo)
                            }
                            aria-expanded="true"
                            aria-controls={
                              "collapse" +
                              RemoverCaractereEspacosAcentos(codigo)
                            }
                          >
                            <div className="d-flex flex-column">
                              <p className="text-500-black-16 mb-3">
                                Ingressos do código
                              </p>
                              <p className="text-500-darkest-14 m-0">
                                {/* Exibir o codigo utilizado */}
                                {codigo}
                              </p>
                            </div>
                          </button>
                        </h2>
                        <div
                          id={
                            "collapse" + RemoverCaractereEspacosAcentos(codigo)
                          }
                          className="row justify-content-center accordion-collapse collapse show"
                          // data-bs-parent="#ticketsAccordion" -> Omit the data-bs-parent attribute on each .accordion-collapse to make accordion items stay open when another item is opened.
                        >
                          {/* Ingressos */}
                          {ingressosComCodigo[codigo].map(
                            (ingresso: IngressoEventoModel) => (
                              <div
                                key={ingresso.id}
                                className="col-11 col-lg-10 mt-3"
                              >
                                <div className="card-ingresso d-flex flex-column flex-sm-row">
                                  {/* Data */}
                                  <div className="data-card-ingresso col col-sm-2 pt-3 p-sm-4">
                                    <p className="m-0 text-dia-ingresso">
                                      {new Date(
                                        ingresso.sessoes[0]
                                      ).toLocaleDateString("pt-BR", {
                                        day: "2-digit",
                                      })}
                                    </p>
                                    <p className="m-0 text-mes-ingresso">
                                      {new Date(
                                        ingresso.sessoes[0]
                                      ).toLocaleDateString("pt-BR", {
                                        month: "short",
                                      })}
                                    </p>
                                  </div>
                                  {/* Nome ingresso + Nome evento */}
                                  <div className="col d-flex flex-column flex-sm-row justify-content-between align-items-center p-3 p-sm-4">
                                    <div className="d-flex flex-column justify-content-center mb-4 mb-sm-0">
                                      <p className="text-500-black-16 text-break m-0">
                                        {ingresso.nome}
                                      </p>
                                      <p className="text-500-black-16 text-break m-0">
                                        {nomeEvento}
                                      </p>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row align-items-center">
                                      {/* Valor (dinâmico) + Qtde */}
                                      <p className="text-400-darkest-16 m-0 mb-3 mb-sm-0 mx-sm-3">
                                        {FormatarCentavosParaReais(
                                          ingresso.valorCentavosSemTaxa
                                        )}
                                      </p>
                                      <div className="d-flex align-items-center">
                                        {(ingressosSelecionados.find(
                                          (i) => i.ingressoId === ingresso.id
                                        )?.quantidade ?? 0) < 1 ? (
                                          <div className="cursor-pointer">
                                            <MinusIcon disabled />
                                          </div>
                                        ) : (
                                          <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                              removeIngresso(ingresso.id)
                                            }
                                          >
                                            <MinusIcon />
                                          </div>
                                        )}
                                        <p
                                          className="text-500-black-16 m-0 text-center"
                                          style={{
                                            width: "50px",
                                            height: "25px",
                                          }}
                                        >
                                          {ingressosSelecionados.find(
                                            (i) => i.ingressoId === ingresso.id
                                          )?.quantidade || 0}
                                        </p>
                                        <div
                                          className="cursor-pointer"
                                          onClick={() =>
                                            addIngresso(
                                              ingresso.id,
                                              ingresso.nome,
                                              ingresso.valorCentavos,
                                              ingresso.valorCentavosSemTaxa,
                                              ingresso.codigoUtilizado,
                                              ingresso.valorTaxaProcessamento,
                                              ingresso.valorTaxaServico
                                            )
                                          }
                                        >
                                          <PlusIcon />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* Botões comprar / código */}
              <div className="row text-center">
                <div className="col-lg-12 mb-3">
                  <Button
                    click={() => comprar(ingressosSelecionados)}
                    className="bc-btn bc-btn-primary"
                    text="Continuar para pagamento"
                    width="384px"
                    disabled={ingressosSelecionados.length === 0}
                  />
                </div>
                <div className="col-lg-12">
                  <button
                    className="bc-link-primary"
                    onClick={() => setModalIsOpen(true)}
                  >
                    Possui algum código?
                  </button>

                  <Modal
                    isOpen={modalIsOpen}
                    close={() => setModalIsOpen(false)}
                    title="Informe o código de acesso"
                    width="448px"
                  >
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <p className="modal-text m-0">
                          Ao inserir o código de acesso, será adicionado os
                          ingressos promocionais na exibição
                        </p>
                      </div>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row mb-3">
                        <div className="col-lg-12">
                          <input
                            autoComplete="off"
                            autoFocus
                            type="text"
                            name="codigo"
                            id="codigo"
                            placeholder="Código"
                            className="mb-2 form-control"
                            value={inputCodigoAcesso}
                            onChange={({ target: { value } }) =>
                              setInputCodigoAcesso(value)
                            }
                          />
                        </div>
                      </div>
                      <div className="row text-end">
                        <div className="col-lg-12">
                          <Button
                            type="button"
                            click={() => setModalIsOpen(false)}
                            text="Cancelar"
                            className="bc-btn bc-btn-gray me-3"
                            width="98px"
                            height="40px"
                          />
                          <Button
                            type="submit"
                            click={() => validarCodigo(inputCodigoAcesso)}
                            text="Validar código"
                            className="bc-btn bc-btn-primary"
                            width="136px"
                            height="40px"
                            disabled={!inputCodigoAcesso}
                          />
                        </div>
                      </div>
                    </form>
                  </Modal>
                </div>
              </div>
            </>
          ) : (
            <p className="text-400-black-16 text-break text-center m-0">
              Não há ingressos disponíveis para este evento.
            </p>
          )}
        </>
      )}
    </>
  );
}
