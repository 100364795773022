import { useEffect, useState } from "react";
import { useIsAuth } from "../Hooks/useIsAuth";
import Button from "./Button";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import Loading from "./Loading";
import AxiosClient from "../Services/AxiosClient";
import { LogoBrasilCashSvg, LogoTicketsSvg } from "../Components";

export default function Header() {
  const isAuth = useIsAuth();
  const navigate = useNavigate();

  const [userImg, setUserImg] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");

  const Logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  const getUserImage = async () => {
    setIsLoading(true);

    AxiosClient.get("/conta/dados-pessoais/imagem-perfil")
      .then(({ data: { data } }) => {
        localStorage.setItem("userImg", data);
        setUserImg(data);
      })
      .catch((err: any) => {
        localStorage.setItem("userImg", "/assets/images/anon_image.png");
        setUserImg("/assets/images/anon_image.png");
        // toast.error(err.response.data.messages[0].message)
      })
      .finally(() => setIsLoading(false));
  };

  const getHeader = async () => {
    const userImg = localStorage.getItem("userImg");

    if (userImg) {
      setUserImg(userImg);
      return;
    }

    await getUserImage();
  };

  useEffect(() => {
    if (!isAuth) return;

    getHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchString: string): void => {
    if (!searchString) {
      navigate("/");
    }

    navigate({
      pathname: "/",
      search: createSearchParams({
        searchString,
      }).toString(),
    });

    return window.location.reload();
  };

  const tenant = process.env.REACT_APP_TENANT;

  return (
    <>
      <header className="container-fluid shadow p-0 bg-white">
        <nav className="bg-white navbar navbar-expand-lg navbar-light container-lg">
          <div className="container-fluid p-0">
            <div className="col-8 col-lg-3 order-1 p-2">
              <Link to="/" reloadDocument>
                {tenant === "BRASILCASH_DEMONSTRACAO" && <LogoBrasilCashSvg />}
                {tenant === "TICKETSAPP" && <LogoTicketsSvg />}
              </Link>
            </div>

            <div className="input-group order-3 order-lg-2 p-2">
              <span className="input-group-text border-0" id="basic-addon1">
                <span className="material-symbols-rounded">search</span>
              </span>
              <input
                className="form-control bc-input input-standard"
                style={{ height: "32px" }}
                name="searchInput"
                type="text"
                value={searchString}
                placeholder="Pesquisar eventos"
                onChange={({ target: { value } }) => setSearchString(value)}
                onKeyDown={({ key }) =>
                  key === "Enter" && handleSearch(searchString)
                }
              />
            </div>

            <div className="col-4 col-lg-3 order-2 order-lg-3 p-2 d-flex align-items-center  justify-content-center justify-content-xxl-end gap-4">
              {isAuth ? (
                <>
                  {/* <span className="material-symbols-rounded fs-5">
                      {" "}
                      confirmation_number{" "}
                    </span>
                    <span className="material-symbols-rounded fs-5">
                      {" "}
                      notifications{" "}
                    </span> */}

                  {isLoading && <Loading loadingSize="1.7rem" />}

                  {!isLoading && userImg && (
                    <div className="dropdown dropstart">
                      <button
                        className="btn p-0 border-0 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={userImg}
                          alt=""
                          width={32}
                          height={32}
                          className="rounded-circle"
                        />
                      </button>
                      <ul
                        className="dropdown-menu dropdown-menu-start"
                        style={{
                          borderRadius: "12px",
                          marginRight: "-50px",
                          marginTop: "50px",
                        }}
                      >
                        <li>
                          <a className="mb-2 dropdown-item" href="/meu-perfil">
                            Editar perfil
                          </a>
                        </li>
                        <li>
                          <a
                            className="mb-2 dropdown-item"
                            href="/meus-ingressos"
                          >
                            Meus ingressos
                          </a>
                        </li>
                        <li>
                          <a
                            className="mb-2 dropdown-item"
                            href="/meus-favoritos"
                          >
                            Meus favoritos
                          </a>
                        </li>
                        <li>
                          <a
                            className="mb-2 dropdown-item"
                            href="/meu-perfil#meus-cartoes"
                          >
                            Meus cartões
                          </a>
                        </li>
                        <li>
                          <a
                            className="mb-2 dropdown-item"
                            href="/minhas-compras"
                          >
                            Minhas compras
                          </a>
                        </li>
                        <li>
                          <a className="mb-2 dropdown-item" href="/ajuda">
                            Ajuda
                          </a>
                        </li>
                        <li>
                          <button onClick={Logout} className="dropdown-item">
                            Sair
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              ) : (
                <Button
                  click={() => navigate("/login")}
                  text="Minha conta"
                  className="bc-btn bc-btn-primary p-2"
                  width="180px"
                  height="40px"
                />
              )}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
