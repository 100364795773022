// import { useParams } from "react-router-dom";
import { Layout, Loading } from "../../Components";

import AxiosClient from "../../Services/AxiosClient";
import ServiceResult from "../../Interfaces/ServiceResult";
import toast from "react-hot-toast";
import IngressoSelecionado from "../../Interfaces/IngressoSelecionado";
import Carrinho from "../../Interfaces/Carrinho";

import { Banner, Mapa, SelecaoIngresso, Sobre } from "./Components";
import { EventoModel } from "../../Interfaces/Models";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import MapaLink from "./Components/MapaLink";

export default function Evento() {
  // evento/6ca21cd7-449d-4da5-9e8d-fc1f2f09a215?codigo=teste123
  const { eventoId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [selecaoIngresso, setSelecaoIngresso] = useState(
    searchParams.get("codigo") ? true : false
  );
  const [evento, setEvento] = useState<EventoModel>({} as EventoModel);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const ValidarCodigo = async (codigoAcesso: string) => {
    setSearchParams(`codigo=${codigoAcesso}`);
  };

  const Comprar = (ingressosSelecionados: IngressoSelecionado[]) => {
    const carrinho: Carrinho = {
      imagemEvento: evento.imagemEvento,
      tituloEvento: evento.titulo,
      dataFimVendas: new Date(evento.dataFimVendas).toLocaleDateString(
        "pt-br",
        {
          day: "2-digit",
          month: "numeric",
          year: "numeric",
        }
      ),
      dataEvento: new Date(evento.dataInicio).toLocaleDateString("pt-br", {
        day: "2-digit",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      }),
      classificacaoEtariaEvento: evento.idadeMinima,
      localEvento: `${evento.endereco?.logradouro} - ${evento.endereco?.cidade}, ${evento.endereco?.unidadeFederacao}`,
      ingressosSelecionados: ingressosSelecionados,
      parcelamentoMaximo: evento.parcelamentoMaximo,
    };

    sessionStorage.setItem("carrinho", JSON.stringify(carrinho));
    navigate("/checkout");
  };

  // obter o evento da api pelo id da url
  const getEvento = async () => {
    setIsLoading(true);

    AxiosClient.get(`/eventos/${eventoId}?codigo=${searchParams.get("codigo")}`)
      .then((response) => {
        const serviceResult: ServiceResult<EventoModel> = response.data;

        setEvento(serviceResult.data as EventoModel);

        if (searchParams.get("codigo")) {
          const codigoUtilizado = serviceResult?.data?.ingressos.some(
            (i) => !!i.codigoUtilizado
          );

          if (codigoUtilizado) {
            toast.success("Código de acesso aplicado com sucesso!");
          } else {
            toast.error("Não foi possível aplicar o código de acesso.");
          }
        }
      })
      .catch((error: any) => {
        navigate("/");
        toast.error("Não foi possível obter o evento solicitado.");
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getEvento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const atualizaEtapa = () => {
    setSelecaoIngresso(!selecaoIngresso);
  };

  return (
    <Layout>
      {isLoading ? (
        <Loading container="50vh" />
      ) : (
        <>
          <Banner
            eventoId={eventoId}
            banner={evento.imagemBannerEvento}
            nome={evento.titulo}
            data={evento.dataInicio}
            local={`${evento.endereco?.logradouro} - ${evento.endereco?.cidade}, ${evento.endereco?.unidadeFederacao}`}
            selecaoIngresso={selecaoIngresso}
            atualizaEtapa={atualizaEtapa}
          />

          {!selecaoIngresso && (
            <Sobre sessoes={evento.sessoes} descricao={evento.exibirDescricao ? evento.descricao : ""} />
          )}

          {!selecaoIngresso && evento.exibirLocalizacao && (
            <MapaLink link={evento.linkSrcLocalizacaoMap} />
          )}

          {selecaoIngresso && (
            <SelecaoIngresso
              nomeEvento={evento.titulo}
              isLoading={isLoading}
              ingressos={evento.ingressos}
              comprar={Comprar}
              validarCodigo={ValidarCodigo}
            />
          )}

          {/* 
          {!selecaoIngresso && evento.localizacao && (
            <Mapa
              lat={evento.localizacao.coordinates[1]}
              lng={evento.localizacao.coordinates[0]}
            />
          )}
        */}
        </>
      )}
    </Layout>
  );
}
