import toast from "react-hot-toast";

import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIsAuth } from "../Hooks/useIsAuth";

type PrivateRouteProps = {
  children: ReactNode;
};

export default function PrivateRoute({ children }: PrivateRouteProps) {
  const navigate = useNavigate();

  const isAuth: boolean = useIsAuth();

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
      toast.error("Você deve realizar o login para acessar essa página.");
    }
  }, [isAuth, navigate]);

  return (
    <>
      {!isAuth && null}
      {isAuth && children}
    </>
  );
}
