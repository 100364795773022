/* eslint-disable react-hooks/exhaustive-deps */
import AxiosClient from "../Services/AxiosClient";
import { useEffect, useState } from "react";
import ServiceResult from "../Interfaces/ServiceResult";
import toast from "react-hot-toast";
import { AuthModel } from "../Interfaces/Models";
import { AxiosResponse } from "axios";

export function useFetch<T = unknown>(url: string, reqAuth: boolean = false) {
  const [response, setResponse] = useState<ServiceResult<T | null>>(
    {} as ServiceResult
  );
  const [isFetching, setIsFetching] = useState<boolean>(true);

  // create a useEffect hook to fetch the data from the url passed in when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      try {
        if (reqAuth) {
          let auth: AuthModel = {} as AuthModel;

          const authLocalStr: string | null = localStorage.getItem("auth");
          const authSessionStr: string | null = sessionStorage.getItem("auth");

          if (authLocalStr) {
            auth = JSON.parse(authLocalStr);
          } else if (authSessionStr) {
            auth = JSON.parse(authSessionStr);
          }

          const res: AxiosResponse<T> = await AxiosClient.get(url, {
            headers: {
              Authorization: `Bearer ${auth.accessToken}`,
            },
          });
          setResponse(res.data as ServiceResult<T>);
        } else {
          const res: AxiosResponse<T> = await AxiosClient.get(url);
          setResponse(res.data as ServiceResult<T>);
        }
      } catch (error: any) {
        setResponse(error.response.data);
        toast.error(error.response.data.message || error.message);
      } finally {
        setIsFetching(false);
      }
    };
    fetchData();
  }, [url]);

  return { response, isFetching };
}

/*

axios
  .get(url)
  .then((response) => {
    if (response instanceof AxiosError) {
      setErroMessage(response.message);
      return;
    }
    setData(response.data);
  })
  .finally(() => {
    setIsFetching(false);
  });

*/
