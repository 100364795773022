import {
  Button,
  Input,
  Loading,
} from "../../../Components";

import EsqueciSenhaArgs from "../../../Interfaces/Args/EsqueciSenhaArgs";
import useEsqueciSenha from "../../../Hooks/useEsqueciSenha";

import { useForm } from "react-hook-form";

export default function FormEsqueciSenha() {
  const { loading, EnviarEmailRedefinicaoSenha } = useEsqueciSenha();

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<EsqueciSenhaArgs>();

  return (
    <>
      <form
        onSubmit={handleSubmit(EnviarEmailRedefinicaoSenha)}
        className="mb-5"
      >
        <div className="form-group mb-5">
          <Input
            control={control}
            name="email"
            defaultValue=""
            validation={{
              required: "Por favor, informe o e-mail.",
              validate: {
                email: (value: string) =>
                  (value.includes("@") && value.includes(".")) ||
                  `O e-mail '${getValues(
                    "email"
                  )}' é inválido. Insira um e-mail corretamente.`,
              },
            }}
            type="text"
            placeholder="exemplo@email.com"
            errors={errors}
          />
          {errors.email && (
            <p className="text-danger text-break m-0 mt-1">
              {errors.email.message?.toString()}
            </p>
          )}
        </div>

        <div className="g-5">
          <div className="col-lg-9 text-center">
            {loading ? (
              <Loading />
            ) : (
              <Button
                width="100%"
                text="Redefinir senha"
                type="submit"
                className="bc-btn bc-btn-primary mb-5"
                disabled={!!errors.email}
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
}
