import { Button, CleanLayout, Input, Loading, Modal } from "../../Components";
import {
  CheckSuccessIcon,
  CheckSuccessModalIcon,
  InfoIcon,
} from "../../Components/Icons";

import AxiosClient from "../../Services/AxiosClient";
import CadastroArgs from "../../Interfaces/Args/CadastroArgs";
import toast from "react-hot-toast";
import useCadastro from "../../Hooks/useCadastro";
import useLogin from "../../Hooks/useLogin";

import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { useValidateToken } from "../../Hooks/useValidateToken";
import SexoCadastro from "../../Enums/SexoCadastro";

const regex = {
  letrasNumeros: /^(?=.*[A-Za-z])(?=.*[0-9]).+$/,
  letraCaixaAlta: /[A-Z]/,
  caracteresEspeciais: /[ `!´¨@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
};

export default function Cadastro() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [confirmacaoLoading, setConfirmacaoLoading] = useState<boolean>(false);

  useValidateToken();

  const { loading, isModalOpen, setIsModalOpen, handleCadastro } =
    useCadastro();

  const { handleLogin, loading: loadingLogin } = useLogin();

  const handleConfirmacao = async (payload: { codigoConfirmacao: string }) => {
    setConfirmacaoLoading(true);

    try {
      await AxiosClient.post("/cadastros/confirmar", {
        codigo: payload.codigoConfirmacao,
        email: getValues("email"),
      });

      toast.success("Cadastro confirmado com sucesso");
      setIsModalOpen(false);

      await handleLogin({
        grant_type: "password",
        lembrarSenha: false,
        user: getValues("email"),
        password: getValues("senha"),
      });
    } catch (err) {
      toast.error("Erro ao confirmar cadastro");
    } finally {
      setConfirmacaoLoading(false);
    }
  };

  const continuarSemConfirmar = async (): Promise<void> => {
    await handleLogin({
      grant_type: "password",
      lembrarSenha: false,
      user: getValues("email"),
      password: getValues("senha"),
    });
  };

  const {
    control,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<CadastroArgs>({
    defaultValues: {
      nomeCompleto: "",
      email: "",
      senha: "",
      confirmarSenha: "",
      cpf: "",
      telefone: "",
      nascimento: "",
      sexo: undefined,
    },
  });

  const confirmacaoForm = useForm<{ codigoConfirmacao: string }>();

  const senha = watch("senha");

  //#region Validações

  const PossuiMinimoDe8Digitos = () => senha.length >= 8;

  const PossuiLetrasENumeros = () => regex.letrasNumeros.test(senha);

  const PossuiLetraCaixaAlta = () => regex.letraCaixaAlta.test(senha);

  const PossuiCaracteresEspeciais = () => regex.caracteresEspeciais.test(senha);

  const SenhaAtendeTodosRequisitos = () => {
    if (
      !PossuiMinimoDe8Digitos() ||
      !PossuiLetrasENumeros() ||
      !PossuiLetraCaixaAlta() ||
      !PossuiCaracteresEspeciais()
    )
      return false;

    return true;
  };

  //#endregion

  return (
    <CleanLayout>
      <p className="title-h1 mt-3 mb-2">Cadastro</p>
      <p className="text-400-black-16">
        Preencha os campos abaixo para criar sua conta
      </p>

      <form onSubmit={handleSubmit(handleCadastro)}>
        <div className="form-group mb-3">
          <Input
            control={control}
            name="nomeCompleto"
            errors={errors}
            placeholder="Nome completo"
            type="text"
            validation={{ required: "Por favor, insira um nome válido" }}
          />
          {errors.nomeCompleto && (
            <p className="text-danger text-break m-0 mt-1">
              {errors.nomeCompleto?.message?.toString()}
            </p>
          )}
        </div>

        <div className="form-group mb-3">
          <Input
            control={control}
            name="email"
            errors={errors}
            placeholder="E-mail"
            type="text"
            validation={{
              required: "Por favor, informe um e-mail",
              validate: {
                email: (value: string) =>
                  (value.includes("@") && value.includes(".")) ||
                  `O e-mail '${value}' é inválido. Insira um e-mail corretamente.`,
              },
            }}
          />
          {errors.email && (
            <p className="text-danger text-break m-0 mt-1">
              {errors.email?.message?.toString()}
            </p>
          )}
        </div>

        <div className="form-group mb-3">
          <div className="d-flex align-items-center">
            <Input
              control={control}
              name="senha"
              errors={errors}
              placeholder="Senha"
              type={showPassword ? "text" : "password"}
              validation={{
                required: "Insira uma senha",
                pattern: {
                  // regex minimo 8 caracteres
                  value: /.{8,}/,
                  message: "A senha deve conter no mínimo 8 caracteres",
                },
              }}
            />
            <button
              type="button"
              className="btn"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <i className="fa-solid fa-eye-slash"></i>
              ) : (
                <i className="fa-solid fa-eye"></i>
              )}
            </button>
          </div>
          {errors.senha && (
            <p className="text-danger text-break m-0 mt-1">
              {errors.senha?.message?.toString()}
            </p>
          )}
        </div>

        <div className="form-group mb-3">
          <div className="d-flex align-items-center">
            <Input
              control={control}
              name="confirmarSenha"
              errors={errors}
              placeholder="Repetir senha"
              type={showPassword ? "text" : "password"}
              validation={{
                required: "Por favor, repita a senha",
                validate: (value, formValues) =>
                  value === formValues.senha || "As senhas não coincidem",
              }}
            />
            <button
              type="button"
              className="btn"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <i className="fa-solid fa-eye-slash"></i>
              ) : (
                <i className="fa-solid fa-eye"></i>
              )}
            </button>
          </div>
          {errors.confirmarSenha && (
            <p className="text-danger text-break m-0 mt-1">
              {errors.confirmarSenha?.message?.toString()}
            </p>
          )}
        </div>

        <div className="g-5 p-3 mb-3 card-requisitos-senha">
          <div className="d-flex align-items-center mb-3">
            <InfoIcon />
            <p className="text-400-darkest-16 ps-2 m-0">
              Sua senha precisa ter no mínimo:
            </p>
          </div>

          <div className="d-flex align-items-center mb-2">
            {PossuiMinimoDe8Digitos() ? (
              <CheckSuccessIcon enabled />
            ) : (
              <CheckSuccessIcon />
            )}
            <p className="text-400-darkest-14 ps-2 m-0">08 dígitos</p>
          </div>

          <div className="d-flex align-items-center mb-2">
            {PossuiLetrasENumeros() ? (
              <CheckSuccessIcon enabled />
            ) : (
              <CheckSuccessIcon />
            )}
            <p className="text-400-darkest-14 ps-2 m-0">Letras e números</p>
          </div>

          <div className="d-flex align-items-center mb-2">
            {PossuiLetraCaixaAlta() ? (
              <CheckSuccessIcon enabled />
            ) : (
              <CheckSuccessIcon />
            )}
            <p className="text-400-darkest-14 ps-2 m-0">
              Letra em caixa alta (maiúscula)
            </p>
          </div>

          <div className="d-flex align-items-center mb-2">
            {PossuiCaracteresEspeciais() ? (
              <CheckSuccessIcon enabled />
            ) : (
              <CheckSuccessIcon />
            )}
            <p className="text-400-darkest-14 ps-2 m-0">
              Caracteres especiais (@#$%&*)
            </p>
          </div>
        </div>

        <div className="form-group mb-3">
          <Input
            control={control}
            name="cpf"
            mask="999.999.999-99"
            errors={errors}
            placeholder="CPF"
            type="text"
            validation={{
              required: "Por favor, insira um CPF",
              pattern: {
                value: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                message: "Informe um CPF válido",
              },
            }}
          />
          {errors.cpf && (
            <p className="text-danger text-break m-0 mt-1">
              {errors.cpf?.message?.toString()}
            </p>
          )}
        </div>

        <div className="form-group mb-3">
          <Input
            control={control}
            name="telefone"
            mask="+55 (99) 99999-9999"
            maskChar="*"
            errors={errors}
            placeholder="Telefone"
            type="text"
            validation={{
              required: "Por favor, insira um número de telefone válido",
            }}
          />
          {errors.telefone && (
            <p className="text-danger text-break m-0 mt-1">
              {errors.telefone?.message?.toString()}
            </p>
          )}
        </div>

        <div className="form-group mb-3">
          <Input
            control={control}
            name="nascimento"
            mask="99/99/9999"
            errors={errors}
            placeholder="Data de nascimento"
            type="text"
            validation={{
              required: "Por favor, insira uma data de nascimento válida",
            }}
          />
          {errors.nascimento && (
            <p className="text-danger text-break m-0 mt-1">
              {errors.nascimento?.message?.toString()}
            </p>
          )}
        </div>

        <Controller
          name="sexo"
          control={control}
          render={({ field }) => (
            <div className="form-group mb-3">
              <select
                {...field}
                className="form-select bc-input input-outlined p-2"
              >
                <option value="" disabled selected>
                  Selecione seu sexo
                </option>
                {Object.values(SexoCadastro).map((sexo) => (
                  <option key={sexo} value={sexo}>
                    {sexo}
                  </option>
                ))}
              </select>
            </div>
          )}
        />

        {loading ? (
          <div className="mb-5">
            <Loading container="0" />
          </div>
        ) : (
          <Button
            className="bc-btn bc-btn-primary mb-5"
            text="Avançar"
            type="submit"
            disabled={!SenhaAtendeTodosRequisitos()}
          />
        )}
      </form>

      <Modal
        closeNonFocus={false}
        close={() => {
          toast.error("Selecione uma opção para prosseguir.");
        }}
        isOpen={isModalOpen}
      >
        <div className="row d-flex justify-content-center align-itens-center text-center">
          <div className="mb-5">
            <CheckSuccessModalIcon />
          </div>

          <p className="title-h2 m-0">
            Enviamos um código de confirmação para seu e-mail ou SMS
          </p>
          <p className="text-400-black-14 mb-4">
            Insira o código abaixo para confirmar seu cadastro
          </p>

          <form onSubmit={confirmacaoForm.handleSubmit(handleConfirmacao)}>
            <div className="form-group mb-5">
              <Input
                control={confirmacaoForm.control}
                mask="999999"
                maskChar="_"
                className="text-center p-2 fs-4"
                style={{ letterSpacing: "0.5rem" }}
                name="codigoConfirmacao"
                errors={confirmacaoForm.formState.errors}
                placeholder="______"
                type="text"
                variant="outlined"
                validation={{
                  required: "Por favor, insira o código de confirmação",
                }}
              />
              {confirmacaoForm.formState.errors.codigoConfirmacao && (
                <p className="text-danger text-break m-0 mt-1">
                  {confirmacaoForm.formState.errors.codigoConfirmacao?.message?.toString()}
                </p>
              )}
            </div>

            {(confirmacaoLoading || loadingLogin) && <Loading />}

            {!confirmacaoLoading && !loadingLogin && (
              <>
                <Button
                  type="submit"
                  text="Confirmar"
                  width="90%"
                  className="bc-btn bc-btn-primary mb-4"
                />
                <Button
                  type="button"
                  click={continuarSemConfirmar}
                  text="Continuar e confirmar mais tarde"
                  width="90%"
                  className="bc-btn bc-btn-light mb-2"
                />
              </>
            )}
          </form>
        </div>
      </Modal>
    </CleanLayout>
  );
}
