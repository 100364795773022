import { ReactNode } from "react";

type CleanLayoutProps = {
  children: ReactNode;
};

export default function CleanLayout({ children }: CleanLayoutProps) {
  return (
    <>
      <div className="container-fluid">
        <div className="row vh-100">
          <div className="col-md-6 col-lg-4 bc-login-bg"></div>
          <div className="col-md-6 col-lg-8 bg-white">
            <div className="row justify-content-center align-items-center h-100">
              <div className="col-lg-6 p-3 p-lg-0 mt-0 mt-md-5">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
