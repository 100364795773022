import EsqueciSenhaArgs from "../Interfaces/Args/EsqueciSenhaArgs";
import AxiosClient from "../Services/AxiosClient";
import toast from "react-hot-toast";

import { useState } from "react";

export default function useEsqueciSenha() {
  const [loading, setLoading] = useState(false);

  const EnviarEmailRedefinicaoSenha = async (data: EsqueciSenhaArgs) => {
    try {
      setLoading(true);

      await AxiosClient.post("/conta/esqueci-minha-senha", data);
      toast.success(`Enviamos um e-mail para ${data.email} com as instruções para redefinir sua senha.`);
    } catch (err) {
      toast.error(`Não foi possível identificar a conta de origem pelo e-mail informado.
      Por favor, confira o e-mail e insira-o novamente.`);
    } finally {
      setLoading(false);
    }
  };

  return { EnviarEmailRedefinicaoSenha, loading }
}