import moment from "moment";
import toast from "react-hot-toast";
import AxiosClient from "../Services/AxiosClient";

import CadastroArgs from "../Interfaces/Args/CadastroArgs";
import ServiceResult from "../Interfaces/ServiceResult";

import { useState } from "react";
import { SomenteNumeros } from "../Utils";
import { useNavigate } from "react-router-dom";
import CadastroSocialArgs from "../Interfaces/Args/CadastroSocialArgs";

export default function useCadastro() {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleCadastro = async (payload: CadastroArgs | CadastroSocialArgs) => {
    try {
      setLoading(true);

      await AxiosClient.post("/cadastros", {
        ...payload,
        sexo:
          "sexo" in payload
            ? payload.sexo === "Prefiro não informar"
              ? null
              : payload.sexo
            : null,
        cpf: SomenteNumeros(payload.cpf),
        telefone: SomenteNumeros(payload.telefone),
        nascimento: moment(payload.nascimento, "DD/MM/YYYY").toDate().toJSON(),
        telefonePaisId: 31,
      });

      setIsModalOpen(true);
    } catch (error: any) {
      if (error.response.status >= 400 && error.response.status < 500) {
        const result: ServiceResult = error.response.data;
        toast.error(result.messages.map((m) => "➡️ " + m.message).join("\n\n"));
      } else {
        toast.error("Ocorreu um erro inesperado. Tente novamente mais tarde.");
      }
    } finally {
      setLoading(false);
    }
  };

  return { loading, isModalOpen, navigate, setIsModalOpen, handleCadastro };
}
