interface WhiteLabelProps {
  colorPrimary: string;
  email: string;
}

const WhiteLabel = (): WhiteLabelProps => ({
  colorPrimary: "#F82853",
  email: "atendimento@ticketsapp.com.br",
});

export default WhiteLabel;
