import Header from "./Header";
import Footer from "./Footer";
import { ReactNode } from "react";

type ChildrenTypes = {
  children: ReactNode;
  background?: string;
};

export default function Layout({ children, background }: ChildrenTypes) {
  return (
    <div style={{ background }}>
      <Header />
      <section className="main-section">
        <div className="container py-4">{children}</div>
      </section>
      <Footer />
    </div>
  );
}
