import { useState } from "react";
import LoginArgs from "../Interfaces/Args/LoginArgs";
import ServiceResult from "../Interfaces/ServiceResult";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AxiosClient from "../Services/AxiosClient";
import { FieldValues } from "react-hook-form";

export default function useLogin(lembrarSenha: boolean = false) {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (data: FieldValues) => {
    try {
      setLoading(true);

      const args: LoginArgs = {
        grant_type: "password",
        user: data.user,
        password: data.password,
        lembrarSenha: lembrarSenha,
      };

      const response = await AxiosClient.postForm("/auth/token", args);

      const result: ServiceResult = response.data;

      if (lembrarSenha) {
        localStorage.setItem("auth", JSON.stringify(result.data));
      } else {
        sessionStorage.setItem("auth", JSON.stringify(result.data));
      }

      // verificar se o usuário está vindo da rota ~/cadastro ou ~/redefinir-senha ou ~/esqueci-senha e redirecionar para Home
      if (
        window.location.pathname === "/cadastro" ||
        window.location.pathname === "/redefinir-senha" ||
        window.location.pathname === "/esqueci-senha"
      ) {
        navigate("/");
      } else {
        // caso contrário, volta 1 página
        navigate(-1);
      }

      toast.success("Bem vindo!!!");
    } catch (error: any) {
      if (error.response.status >= 400 && error.response.status < 500) {
        const result: ServiceResult = error.response.data;
        toast.error(result.messages.map((m) => "➡️ " + m.message).join("\n\n"));
      } else {
        toast.error("Ocorreu um erro inesperado. Tente novamente mais tarde.");
      }
    } finally {
      setLoading(false);
    }
  };

  return { handleLogin, loading };
}
