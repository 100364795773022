import { BreadCrumb, Loading, Layout, CardEvento } from "../../Components";
import { Page } from "../../Components/Breadcrumb";

import AxiosClient from "../../Services/AxiosClient";
import RolagemInfinita from "../../Interfaces/RolagemInfinita";
import ServiceResult from "../../Interfaces/ServiceResult";
import toast from "react-hot-toast";

import { EventoCardModel } from "../../Interfaces/Models";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "Página inicial",
  },
  {
    link: "/meus-favoritos",
    name: "Meus favoritos",
  },
];

export default function MeusFavoritos() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [favoritos, setFavoritos] = useState<EventoCardModel[]>(
    [] as EventoCardModel[]
  );

  // TODO: PAGINAÇÃO DE EVENTOS FAVORITOS
  const ObterEventosFavoritos = async () => {
    setIsLoading(true);

    AxiosClient.get<ServiceResult<RolagemInfinita<EventoCardModel>>>(
      "/eventos?favoritos=true"
    )
      .then(({ data: { data } }) => {
        setFavoritos(data?.registros ?? ([] as EventoCardModel[]));
      })
      .catch((error) => {
        toast.error(
          "Erro ao carregar os favoritos. Tente novamente mais tarde."
        );
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    ObterEventosFavoritos();
  }, []);

  return (
    <Layout>
      <div className="row g-3">
        <BreadCrumb history={breadCrumbHistory} />

        <div className="col-lg-12">
          <div
            className="bg-white py-3 px-3 px-lg-5 shadow-sm"
            style={{ minHeight: "calc(100vh - 339px)" }}
          >
            <p className="title-h1 text-black m-0">Meus favoritos</p>

            <div className="row g-3">
              {isLoading && <Loading container="50vh" />}

              {!isLoading &&
                favoritos?.map((e) => (
                  <Link
                    to={`/evento/${e.id}`}
                    key={e.id}
                    className="col-xs-6 col-sm-6 col-md-4 col-lg-3 mt-4 mt-lg-5 d-flex justify-content-center text-decoration-none"
                  >
                    <CardEvento
                      imagemThumbEvento={e.imagemThumbEvento}
                      dataInicio={e.dataInicio}
                      titulo={e.titulo}
                    />
                  </Link>
                ))}

              {!isLoading && favoritos?.length === 0 && (
                <p className="mt-5 text-center">
                  Você não possui nenhum ingresso favoritado.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
