import {
  Button,
  CardEvento,
  Footer,
  Header,
  Loading,
} from "../../Components"

import AxiosClient from "../../Services/AxiosClient";
import Carousel from "./Components/Carousel";
import toast from "react-hot-toast";
import ServiceResult from "../../Interfaces/ServiceResult";
import RolagemInfinita from "../../Interfaces/RolagemInfinita";

import { BannerModel, EventoCardModel } from "../../Interfaces/Models";
import { Link, useSearchParams } from "react-router-dom";
import { useFetch } from "../../Hooks/useFetch";
import { useEffect, useState } from "react";

export default function Home() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const [possuiMaisEventos, setPossuiMaisEventos] = useState<boolean>(true);
  const [pagina, setPagina] = useState<number>(1);
  const [eventos, setEventos] = useState<EventoCardModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const bannersCarregados = useFetch<BannerModel[]>("/banners");

  const getEventos = async (searchString: string) => {
    setIsLoading(true);

    AxiosClient.get<ServiceResult<RolagemInfinita<EventoCardModel>>>(
      `/eventos?searchString=${searchString}&pagina=${pagina}&paginaTamanho=12`
    )
      .then(({ data: { data } }) => {
        setEventos((prevValue) => [...prevValue, ...data!.registros]);
        setPossuiMaisEventos(data!.possuiMais);
      })
      .catch((err: any) => toast.error(err.response.data.messages[0].message))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getEventos(searchParams.get("searchString") || "");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina]);

  const carregarMaisEventos = () => {
    setPagina(pagina + 1);
  };

  return (
    <>
      <Header />
      <section className="main-section">
        {/* BANNERS */}
        {bannersCarregados.isFetching && <Loading container="50vh" />}
        {!bannersCarregados.isFetching &&
          bannersCarregados.response.success &&
          bannersCarregados.response.data && (
            <Carousel banners={bannersCarregados.response.data} />
          )}

        {/* EVENTOS */}
        {isLoading && <Loading container="50vh" />}
        {!isLoading && (
          <div className="container py-4">
            <div className="row g-3">
              {eventos.map((e) => (
                <Link
                  to={`/evento/${e.id}`}
                  key={e.id}
                  className="col-xs-6 col-sm-6 col-md-4 col-lg-3 mt-4 mt-lg-5 d-flex justify-content-center text-decoration-none"
                >
                  <CardEvento
                    imagemThumbEvento={e.imagemThumbEvento}
                    dataInicio={e.dataInicio}
                    titulo={e.titulo}
                  />
                </Link>
              ))}

              <div className="col-lg-12 pt-5 d-flex justify-content-center">
                <Button
                  click={carregarMaisEventos}
                  className="bc-btn bc-btn-primary"
                  width="200px"
                  text="Carregar mais"
                  disabled={!possuiMaisEventos}
                />
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
}
