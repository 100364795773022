// import { useForm } from "react-hook-form";
import { CertificadoSSLIcon } from "../../../Components/Icons";

import IngressoSelecionado from "../../../Interfaces/IngressoSelecionado";

import { FormatarCentavosParaReais } from "../../../Utils";
// import Input from "../Common/Input";

// const AplicarCupom = (data: CupomArgs) => {
//   alert(`TESTE CUPOM ENVIADO: ${data.cupom}`);
// };

interface InfoCheckoutIngressoCupomProps {
  imagemEvento: string;
  dataFimVendas: string;
  ingressos: IngressoSelecionado[];
}

export default function InfoCheckoutIngressoCupom({
  imagemEvento,
  dataFimVendas,
  ingressos,
}: InfoCheckoutIngressoCupomProps) {
  // const {
  //   control,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm<CupomArgs>();

  return (
    <div className="col-lg-4">
      <div className="bg-white px-2 py-2 shadow-sm">
        {ingressos.map((i) => (
          <div
            className="d-flex flex-wrap justify-content-start align-items-center"
            key={i.ingressoId}
          >
            {/* Img evento */}
            <img
              src={imagemEvento}
              style={{
                width: "118px",
                height: "118px",
                borderRadius: "5px",
                margin: "8px",
              }}
              alt="evento"
            />
            {/* Ingresso / qtde / valor + taxa / data limite vendas */}
            <div className="d-flex flex-column justify-content-center">
              <p className="text-500-black-18 text-break mb-1">{`${i.quantidade}x ${i.nome}`}</p>
              <p className="text-500-black-16 mb-1">
                {FormatarCentavosParaReais(i.valorCentavosSemTaxa)} + (
                {FormatarCentavosParaReais(i.valorTaxaServico)})
              </p>
              <p className="text-500-dark-16 mb-1">
                Vendas até {dataFimVendas}
              </p>
            </div>
          </div>
        ))}

        {/* Valor do ingresso */}
        {/* <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
          <p className="text-500-black-14 m-0">Valor do ingresso</p>
          <p className="text-500-black-14 m-0">R$ 35,00</p>
        </div> */}

        {/* Taxa do ingresso */}
        {/* <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
          <p className="text-500-black-14 m-0">Taxas</p>
          <p className="text-500-black-14 m-0">R$ 25,00</p>
        </div> */}

        {/* Descontos */}
        {/* <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
          <p className="text-500-black-14 m-0">Descontos</p>
          <p className="text-500-black-14 m-0" style={{ color: "#52CC6C" }}>
            -R$ 5,00
          </p>
        </div> */}

        {/* Valor ingressos */}
        <div className="px-3 mb-2 d-flex align-items-center justify-content-between mt-4">
          <p className="text-500-black-14 m-0">Valor do(s) ingresso(s)</p>
          <p className="text-500-black-14 m-0">
            {FormatarCentavosParaReais(
              ingressos?.reduce((acc, curr) => {
                return acc + curr.valorCentavosSemTaxa * curr.quantidade;
              }, 0)
            )}
          </p>
        </div>

        {/* Taxa de serviço */}
        <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
          <p className="text-500-black-14 m-0">Taxa de serviço</p>
          <p className="text-500-black-14 m-0">
            {FormatarCentavosParaReais(
              ingressos?.reduce((acc, curr) => {
                return acc + curr.valorTaxaServico * curr.quantidade;
              }, 0)
            )}
          </p>
        </div>

        {/* Taxa de processamento */}
        <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
          <p className="text-500-black-14 m-0">Processamento</p>
          <p className="text-500-black-14 m-0">
            {FormatarCentavosParaReais(
              ingressos?.reduce((acc, curr) => {
                return acc + curr.valorTaxaProcessamento * curr.quantidade;
              }, 0)
            )}
          </p>
        </div>

        {/* Valor total */}
        <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
          <p className="text-500-black-14 m-0">Valor total</p>
          <p className="text-500-black-14 m-0">
            {FormatarCentavosParaReais(
              ingressos?.reduce((acc, curr) => {
                return acc + curr.valorCentavos * curr.quantidade;
              }, 0)
            )}
          </p>
        </div>

        {/* Cupom */}
        {/* <div className="mt-4 px-3 d-flex flex-column">
          <p className="text-400-black-16 m-0">Possui um cupom de desconto?</p>
          <form onSubmit={handleSubmit(AplicarCupom)}>
            <Input
              control={control}
              defaultValue=""
              name="cupom"
              hasErrors={!!errors.cupom}
              rules={{
                required: "Por favor, informe um cupom para ser aplicado",
              }}
              type="text"
              className="my-2"
              placeholder="Cupom"
              variant="outlined"
            />
            {errors.cupom && (
              <p className="text-danger text-break m-0 mt-1">
                {errors.cupom.message?.toString()}
              </p>
            )}

            <button type="submit" className="bc-link-primary w-100 text-center">
              Aplicar cupom
            </button>
          </form>
        </div> */}
      </div>

      <div className="d-flex flex-column align-items-end mt-4">
        <p className="text-500-black-14 m-0">Pagamento seguro</p>
        <div
          className="my-2 w-75"
          style={{ border: "1px solid #45395B" }}
        ></div>
        <CertificadoSSLIcon />
      </div>
    </div>
  );
}
