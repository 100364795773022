import { Button, Layout, Loading } from "../../Components";
import { CalendarIcon, LocalIcon } from "../../Components/Icons";

import AxiosClient from "../../Services/AxiosClient";
import ServiceResult from "../../Interfaces/ServiceResult";
import toast from "react-hot-toast";

import { EventoModel } from "../../Interfaces/Models";
import { Mapa, Sobre } from "../Evento/Components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function IngressoAguardandoAprovacao() {
  const { eventoId, ingressoId } = useParams();

  const navigate = useNavigate();

  const [evento, setEvento] = useState<EventoModel>({} as EventoModel);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // obter o evento da api pelo id da url
  const getEvento = async (): Promise<void> => {
    setIsLoading(true);

    AxiosClient.get(`/eventos/${eventoId}`)
      .then(({ data: { data } }) => setEvento(data))
      .catch((error: any) => {
        navigate("/meus-ingressos");
        toast.error("Você não recebeu nenhum ingresso desse evento.");
      })
      .finally(() => setIsLoading(false));
  };

  const receberIngresso = async (aceitar: boolean): Promise<void> => {
    setIsLoading(true);

    AxiosClient.put(`/conta/transferencias/${ingressoId}`, { aceitar })
      .then((response) => {
        navigate("/meus-ingressos");
      })
      .catch((error: any) => {
        if (error.response.status >= 400 && error.response.status < 500) {
          const result: ServiceResult = error.response.data;
          toast.error(
            result.messages.map((m) => "➡️ " + m.message).join("\n\n"),
          );
        } else {
          toast.error(
            "Ocorreu um erro inesperado. Tente novamente mais tarde.",
          );
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getEvento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      {isLoading ? (
        <Loading container="50vh" />
      ) : (
        <>
          <div className="row mb-4">
            <div className="col-lg-12">
              <div className="row">
                <img
                  className="p-0"
                  src={evento.imagemBannerEvento}
                  alt="banner evento"
                  width="100%"
                  height="100%"
                  style={{ maxHeight: "350px", borderRadius: "8px 8px 0 0" }}
                />
              </div>
              <div
                className="row bg-black"
                style={{ borderRadius: "0 0 8px 8px" }}
              >
                <div className="col-lg-6">
                  {/* nome do evento */}
                  <p className="my-2 title-h2 text-white text-break">
                    {evento.titulo}
                  </p>
                  <div className="col-lg-12 d-flex flex-wrap align-items-center">
                    {/* icon + data */}
                    <div className="pb-3 d-flex align-items-center">
                      <div className="pe-2">
                        <CalendarIcon />
                      </div>

                      <p className="m-0 me-4 text-500-white-16">
                        {new Date(evento.dataInicio).toLocaleDateString(
                          "pt-br",
                          {
                            day: "2-digit",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                          },
                        )}
                      </p>
                    </div>

                    {/* icon + local */}
                    <div className="pb-3 d-flex align-items-center">
                      <div className="pe-2">
                        <LocalIcon />
                      </div>
                      <p className="m-0 me-4 text-500-white-16">{`${evento.endereco?.logradouro} - ${evento.endereco?.cidade}, ${evento.endereco?.unidadeFederacao}`}</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 my-3 my-lg-0 d-flex align-items-center justify-content-center">
                  <Button
                    click={() => receberIngresso(true)}
                    className="btn btn-success p-2"
                    width="384px"
                    text="Aceitar ingresso"
                    // iconSvg={<CheckIcon />}
                  />
                </div>

                {/* Botão: Ver ingressos */}
                <div className="col-lg-3 mb-3 mb-lg-0 d-flex align-items-center justify-content-center">
                  <Button
                    click={() => receberIngresso(false)}
                    className="btn btn-danger p-2"
                    width="384px"
                    text="Recusar ingresso"
                    // iconSvg={<CloseIcon />}
                  />
                </div>
              </div>
            </div>
          </div>

          <Sobre sessoes={evento.sessoes} descricao={evento.descricao} />

          {evento.localizacao && (
            <Mapa
              lat={evento.localizacao.coordinates[1]}
              lng={evento.localizacao.coordinates[0]}
            />
          )}
        </>
      )}
    </Layout>
  );
}
