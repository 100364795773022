import ReactInputMask from "react-input-mask";
import { ProfileContext } from "../../../Contexts/ProfileContext";

import toast from "react-hot-toast";

import { EstadosBrasileiros, SomenteNumeros } from "../../../Utils";
import { useContext, useEffect, useState } from "react";
import { useViaCep } from "../../../Hooks/useViaCep";
import { ViaCepModel } from "../../../Interfaces/Models";
import { Loading } from "../../../Components";

export default function MeuEndereco() {
  const {
    setPerfil,
    enderecoEditavel,
    setEnderecoEditavel,
    botaoSalvarDesabilitado,
    setBotaoSalvarDesabilitado,
    ...dadosPessoais
  } = useContext(ProfileContext);

  const [enderecoViaCepResponse, setEnderecoViaCepResponse] =
    useState<ViaCepModel>({} as ViaCepModel);

  const { FetchCep, fetchingCep } = useViaCep();

  const BuscarCep = async (cep: string) => {
    const viaCepResponse = await FetchCep(cep);

    setEnderecoViaCepResponse(viaCepResponse);

    if (viaCepResponse.erro) {
      setBotaoSalvarDesabilitado(true);
      setEnderecoEditavel(false);
    } else {
      setEnderecoEditavel(true);
      setBotaoSalvarDesabilitado(false);
    }
  };

  useEffect(() => {
    if (
      dadosPessoais.endereco?.cep &&
      SomenteNumeros(dadosPessoais.endereco?.cep).length === 8
    )
      BuscarCep(dadosPessoais.endereco?.cep);

    if (
      !dadosPessoais.endereco?.cep ||
      SomenteNumeros(dadosPessoais.endereco?.cep).length < 8
    ) {
      setBotaoSalvarDesabilitado(true);
      setEnderecoEditavel(false);
      setPerfil((prev) => ({
        ...prev,
        endereco: {
          ...prev.endereco,
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          cidade: "",
          unidadeFederacao: "",
          pais: "BR",
        },
      }));
    }

    if (
      !dadosPessoais.endereco?.cep ||
      dadosPessoais.endereco?.cep.length === 0
    ) {
      setBotaoSalvarDesabilitado(false);
      setEnderecoEditavel(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosPessoais.endereco?.cep]);

  useEffect(() => {
    if (enderecoViaCepResponse.erro) {
      toast.error("CEP inválido! Não foi possível obter o endereço.");
      return;
    }

    if (enderecoViaCepResponse.cep) {
      setPerfil((prev) => {
        return {
          ...prev,
          endereco: {
            ...prev.endereco,
            cep: enderecoViaCepResponse.cep,
            logradouro: enderecoViaCepResponse.logradouro,
            bairro: enderecoViaCepResponse.bairro,
            cidade: enderecoViaCepResponse.localidade,
            pais: "BR",
            unidadeFederacao: enderecoViaCepResponse.uf,
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enderecoViaCepResponse]);

  return (
    <div className="col-lg-12">
      <div className="bg-white py-3 px-3 px-lg-5 shadow-sm">
        <p className="title-h1 text-black mb-3 mb-lg-4">Meu endereço</p>

        <div className="row g-5">
          {fetchingCep ? (
            <div className="col-lg-8 d-flex justify-content-center items-align-center">
              <Loading />
            </div>
          ) : (
            <div className="col-lg-8">
              {/* CEP */}
              <div className="row g-4 mb-3 mb-lg-4">
                <div className="col-lg-4">
                  <label htmlFor="cep" className="text-500-dark-18">
                    CEP
                  </label>
                  <ReactInputMask
                    className="form-control bc-input input-outlined p-2"
                    value={dadosPessoais.endereco?.cep}
                    type="text"
                    name="cep"
                    placeholder="CEP"
                    height="24px"
                    mask="99999-999"
                    onChange={({ target: { value } }) => {
                      setPerfil((prev) => ({
                        ...prev,
                        endereco: { ...prev.endereco, cep: value },
                      }));
                    }}
                  />
                </div>
                <div className="col-lg-4 d-flex align-items-end mb-2">
                  <a
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Não sei meu CEP
                  </a>
                </div>
              </div>

              {/* Endereço (Logradouro) */}
              <div className="row mb-3 mb-lg-4">
                <div className="col-lg-12">
                  <label htmlFor="endereco" className="text-500-dark-18">
                    Endereço (Logradouro)
                  </label>
                  <ReactInputMask
                    disabled={!enderecoEditavel}
                    className="form-control bc-input input-outlined p-2"
                    mask=""
                    value={dadosPessoais.endereco?.logradouro}
                    type="text"
                    name="endereco"
                    placeholder="Endereço"
                    height="24px"
                    onChange={({ target: { value } }) => {
                      setPerfil((prev) => ({
                        ...prev,
                        endereco: { ...prev.endereco, logradouro: value },
                      }));
                    }}
                  />
                </div>
              </div>

              {/* Número / Complemento / Bairro */}
              <div className="row g-4 mb-3 mb-lg-4">
                <div className="col-lg-4">
                  <label htmlFor="numero" className="text-500-dark-18">
                    Número
                  </label>
                  <ReactInputMask
                    className="form-control bc-input input-outlined p-2"
                    disabled={!enderecoEditavel}
                    mask=""
                    value={dadosPessoais.endereco?.numero}
                    type="text"
                    name="numero"
                    placeholder="Número"
                    height="24px"
                    onChange={({ target: { value } }) => {
                      setPerfil((prev) => ({
                        ...prev,
                        endereco: { ...prev.endereco, numero: value },
                      }));
                    }}
                  />
                </div>

                <div className="col-lg-4">
                  <label htmlFor="complemento" className="text-500-dark-18">
                    Complemento
                  </label>
                  <ReactInputMask
                    className="form-control bc-input input-outlined p-2"
                    disabled={!enderecoEditavel}
                    mask=""
                    value={dadosPessoais.endereco?.complemento}
                    type="text"
                    name="complemento"
                    placeholder="Complemento"
                    height="24px"
                    onChange={({ target: { value } }) => {
                      setPerfil((prev) => ({
                        ...prev,
                        endereco: { ...prev.endereco, complemento: value },
                      }));
                    }}
                  />
                </div>

                <div className="col-lg-4">
                  <label htmlFor="bairro" className="text-500-dark-18">
                    Bairro
                  </label>
                  <ReactInputMask
                    disabled={!enderecoEditavel}
                    className="form-control bc-input input-outlined p-2"
                    mask=""
                    value={dadosPessoais.endereco?.bairro}
                    type="text"
                    name="bairro"
                    placeholder="Bairro"
                    height="24px"
                    onChange={({ target: { value } }) => {
                      setPerfil((prev) => ({
                        ...prev,
                        endereco: { ...prev.endereco, bairro: value },
                      }));
                    }}
                  />
                </div>
              </div>

              {/* País / Estado / Cidade */}
              <div className="row g-4 mb-3 mb-lg-4">
                <div className="col-lg-4">
                  <label htmlFor="pais" className="text-500-dark-18">
                    País
                  </label>
                  <select
                    disabled={!enderecoEditavel}
                    className="form-select bc-input input-outlined p-2"
                    id="pais"
                    name="pais"
                    value={dadosPessoais.endereco?.pais}
                    onChange={({ target: { value } }) => {
                      setPerfil((prev) => ({
                        ...prev,
                        endereco: { ...prev.endereco, pais: value },
                      }));
                    }}
                  >
                    <option value="BR">Brasil</option>
                  </select>
                </div>

                <div className="col-lg-4">
                  <label htmlFor="estado" className="text-500-dark-18">
                    Estado
                  </label>
                  <select
                    disabled={!enderecoEditavel}
                    className="form-select bc-input input-outlined p-2"
                    id="estado"
                    name="estado"
                    value={dadosPessoais.endereco?.unidadeFederacao}
                    onChange={({ target: { value } }) => {
                      setPerfil((prev) => ({
                        ...prev,
                        endereco: { ...prev.endereco, unidadeFederacao: value },
                      }));
                    }}
                  >
                    <option value="">Selecione um estado</option>
                    {EstadosBrasileiros.map((estado) => (
                      <option key={estado.sigla} value={estado.sigla}>
                        {estado.nome}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-4">
                  <label htmlFor="cidade" className="text-500-dark-18">
                    Cidade
                  </label>
                  <ReactInputMask
                    disabled={!enderecoEditavel}
                    className="form-control bc-input input-outlined p-2"
                    mask=""
                    value={dadosPessoais.endereco?.cidade}
                    type="text"
                    name="cidade"
                    placeholder="Cidade"
                    height="24px"
                    onChange={({ target: { value } }) => {
                      setPerfil((prev) => ({
                        ...prev,
                        endereco: { ...prev.endereco, cidade: value },
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
