import { useState } from "react";

type SobreProps = {
  descricao: string;
  sessoes: string[];
};

function toDescricoes(descricao: string): string[] {
  const descricoes: string[] = [];
  if (descricao.indexOf("\r\n") > -1) {
    descricoes.push(...descricao.split("\r\n"));
  } else {
    descricoes.push(...descricao.split("\n"));
  }
  return descricoes;
}

function descricaoCurta(descricoes: string[]): JSX.Element {
  if (descricoes.length === 0) {
    return <p></p>;
  }

  const descricao = descricoes[0];

  if (descricao.length <= 200) {
    return <p className="text-400-darkest-16 mb-3 text-break">{descricao}...</p>;
  }

  const inicio_desc = descricao.substring(0, 200);
  const fim_desc = descricao.substring(200, descricao.length);
  const fim_desc_espaco = fim_desc.indexOf(" ");

  if (fim_desc_espaco === -1) {
    return <p className="text-400-darkest-16 mb-3 text-break">{inicio_desc}...</p>;
  }

  return <p className="text-400-darkest-16 mb-3 text-break">
    {inicio_desc + fim_desc.substring(0, fim_desc_espaco)}...
  </p>;
}

function descricaoCompleta(descricoes: string[]): JSX.Element[] {
  if (descricoes.length === 0) {
    return [<p></p>];
  }

  return descricoes.map((d: string, index: number) => <p key={index}>{d}</p>);
}

export default function Sobre(props: SobreProps) {
  const { descricao, sessoes } = props;

  const [textoExpandido, setTextoExpandido] = useState(
    toDescricoes(descricao).length === 1
  );

  const formatarDataObterDiaDaSemana = (data: string): string => {
    return new Date(data)
      .toLocaleDateString("pt-BR", {
        weekday: "short",
      })
      .toString()
      .toUpperCase()
      .replace(".", "");
  };

  const formatarDataObterDia = (data: string): string => {
    return new Date(data).toLocaleDateString("pt-BR", {
      day: "2-digit",
    });
  };

  const formatarDataObterMes = (data: string): string => {
    return new Date(data)
      .toLocaleDateString("pt-BR", {
        month: "short",
      })
      .toString()
      .toUpperCase()
      .replace(".", "");
  };

  const formatarDataObterHora = (data: string): string => {
    return new Date(data).toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="row">
      <div className="col-lg-12 mb-2">
        <p className="title-h1 mb-2">Sessões do evento</p>
        <div className="grupo-sessoes mb-4">
          {sessoes.map((d: string, index: number) => (
            <div key={index} className="grupo-card-sessao">
              <div className="card-sessao">
                <p className="m-0 text-mes-ingresso">
                  {formatarDataObterDiaDaSemana(d)}
                </p>
                <p className="m-0 text-dia-ingresso">
                  {formatarDataObterDia(d)}
                </p>
                <p className="m-0 text-mes-ingresso">
                  {formatarDataObterMes(d)}
                </p>
              </div>
              <p className="m-0 mt-2 text-mes-ingresso">
                {formatarDataObterHora(d)}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* Descrição do evento */}
      {descricao.length > 0 && (
        <div className="col-lg-12">
          <p className="title-h1">Sobre o evento</p>
          <p className="text-400-darkest-16 mb-3 text-break">
            {textoExpandido
              ? descricaoCompleta(toDescricoes(descricao))
              : descricaoCurta(toDescricoes(descricao))}
            {toDescricoes(descricao).length > 1 && (
              <span
                className="link-primary cursor-pointer m-1"
                onClick={() => setTextoExpandido(!textoExpandido)}
              >
                {textoExpandido ? "Ler menos" : "Ler mais"}
              </span>
            )}
          </p>
        </div>
      )}
    </div>
  );
}
