type CardEventoProps = {
  titulo: string;
  dataInicio: string;
  imagemThumbEvento: string | null;
  concluido?: boolean;
};

export default function CardEvento({
  imagemThumbEvento,
  titulo,
  dataInicio,
  concluido,
}: CardEventoProps) {
  return (
    <div className="card-evento">
      <img
        src={imagemThumbEvento || ""}
        alt={titulo}
        className="img-card-evento mb-2"
        style={{ filter: concluido ? "blur(1px) grayscale(1)" : "" }}
      />
      <p className="text-500-black-16 text-break mb-1">
        {titulo.length > 35 ? `${titulo.substring(0, 35)}...` : titulo}
      </p>
      {dataInicio ? (
        <p className="text-500-darkest-16 m-0">
          {new Date(dataInicio).toLocaleDateString("pt-br", {
            day: "2-digit",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </p>
      ) : (
        <p className="text-500-darkest-16 m-0">Nenhuma sessão disponível.</p>
      )}
    </div>
  );
}
